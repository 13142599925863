// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "GraffitiDynamics-module--CarrouselWrapper2--Tahtg";
export var DescriptionWrapper = "GraffitiDynamics-module--DescriptionWrapper--eUTq-";
export var DownloaderWrapper = "GraffitiDynamics-module--DownloaderWrapper--6rpxu";
export var EventWrapper = "GraffitiDynamics-module--EventWrapper--1G4Ui";
export var ImageWrapper = "GraffitiDynamics-module--ImageWrapper--yB9YH";
export var ImagesWrapper = "GraffitiDynamics-module--ImagesWrapper--KDu-P";
export var MobileTile = "GraffitiDynamics-module--MobileTile--iZt0Q";
export var PdpWrapper = "GraffitiDynamics-module--PdpWrapper--fHLc8";
export var PhotosWrapper = "GraffitiDynamics-module--PhotosWrapper--hRCDG";
export var TitleWrapper = "GraffitiDynamics-module--TitleWrapper--6-1DS";
export var Wrapper = "GraffitiDynamics-module--Wrapper--AdfxS";