import React from "react";
import { Wrapper, ImageWrapper } from "./Carrousel.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Carrousel = ({ images }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };

  return (
    <div className={Wrapper}>
      <Carousel
        partialVisbile={false}
        slidesToSlide={1}
        ssr
        arrows={true}
        swipeable={true}
        draggable={true}
        responsive={responsive}
        infinite={false}
        customTransition="transform 500ms ease-in-out"
        transitionDuration={500}
        autoPlay={true}
        autoPlaySpeed={3000}
        removeArrowOnDeviceType={["mobile"]}
        showDots={false}
        >
        {/* TODO: Faire les cartel ici */}
        {images.map((image) => (
            <img
              className={ImageWrapper}
              key={image.name}
              alt={image.name}
              src={image.src}
            />
        ))}
      </Carousel>
    </div>
  );
};

export default Carrousel;
