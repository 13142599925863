import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./GraffitiDynamics.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import Tile from "./../../../../res/graffitiDynamics/tile.png";
import InSitu1 from "./../../../../res/graffitiDynamics/insitu-1.png";
import InSitu2 from "./../../../../res/graffitiDynamics/insitu-2.png";
import InSitu3 from "./../../../../res/graffitiDynamics/insitu-3.png";

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: InSitu1, name: "InSitu4" },
    { src: InSitu2, name: "InSitu2" },
    { src: InSitu3, name: "InSitu3" },
  ],
};

const GraffitiDynamics = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="GraffitiDynamics"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>GRAFFITI DYNAMICS<h2 style={{paddingLeft: "16px"}}></h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="GraffitiDynamics"/>
        <br />
        <p>
        GHOST galerie a le plaisir de vous présenter sa nouvelle exposition : Graffiti Dynamics, du 15 juin au 5 août 2023, entre Paris et Marseille. Une immersion dans la scène artistique New-Yorkaise des années 80 et dans la scène actuelle !

En ce printemps 2023, le Post-graffiti, le graffiti, et différentes expressions de rue sont exposés au sein d'institutions prestigieuses telles que la Fondation Vuitton ou le Palais de Tokyo. Ces deux lieux emblématiques mettent en lumière cette scène artistique crée par une jeunesse rebelle.

Ce nouvel accrochage entend rendre hommage à ces maîtres de l'aérosol (Futura 2000, Dondi White, A-one, Daze, Crash, Lee Quinones) avec des oeuvres des années 80-90, des tirages photographiques d'Henry Chalfant, ainsi que des oeuvres d'artistes qui ont marqués l'histoire de la scène de l'East village (Kenny Scharf, Richard Hambleton, John Fekner) de l'art "underground" (Rammellzee, Chaz Bojorquez) ou de la scène actuelle (Invader, Kaws).

Mais cet accrochage a aussi été imaginé afin d'offrir une expérience immersive voir introspective. En partenariat avec TV Store, vous pourrez revivre cette effervescence artistique au travers d'une double installation vidéo, un voyage dans le temps dans le New-York des années 80.
        </p>
          {/* <a className={DownloaderWrapper} href={DazePdf}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a> */}
        </div>
      </div>
      <br />
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default GraffitiDynamics;
